
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, randomAssetNameForDemo, sleep } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import metaDataModule from '@/store/modules/metaDataModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import workflowModule from '@/store/modules/workflowModule';
import { mixins } from 'vue-class-component';
import GptMixin from './GptMixin';
import axiosClient from '@/lib/rest/axiosClient';
import gptModule from '@/store/modules/gptModule';
import VirtualList from 'vue-virtual-scroll-list';
import TasqAIGroupItem from './TasqAIGroupItem.vue';
import axios from 'axios';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import { re } from 'mathjs';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    GptAutoSuggestion: () => getComponent('gpt/GptAutoSuggestionV2'),
    TasqAiGroupItem: () => getComponent('gpt/TasqAIGroupItem'),
    TasqAiAudioEntry: () => getComponent('gpt/TasqAIAudioEntry'),
    TasqAiCommentEntry: () => getComponent('gpt/TasqAICommentEntry'),
    GroupDataView: () => getComponent('gpt/GroupDataView'),
    AIGroupHeader: () => getComponent('gpt/AIGroupHeader'),
    GroupsList: () => getComponent('gpt/GroupsList'),
    AudioStreaming: () => getComponent('gpt/AudioStreaming'),
    WellSignalsSearch: () => getComponent('tasqs/WellSignalsSearch'),
    ConfirmPopup: () => getComponent('tasqsCommon/ConfirmPopup'),
    GroupModelView: () => getComponent('gpt/GroupModelView'),
    WorkflowView: () => getComponent('gpt/WorkflowView'),
    DateRangePicker,
    draggable,
    VirtualList,
  },
})
export default class Groups extends mixins(GptMixin) {
  @Prop({ type: String, required: false, default: '' }) searchQueryPassed?: any;

  selectedActions: any = [];

  showWellSignalSearch = false;

  randomAssetNameForDemo = randomAssetNameForDemo;

  showGroupSettings = false;

  isSavingNewTasq = false;

  enableFeedback = false;

  gptAiQueryGroup = {
    query: '',
    wells: [],
    sqls: '',
    signalTypes: [
      'Flowrate',
      'Tubing Pressure',
      'Static Pressure',
      'Casing Pressure',
    ],
  };

  selectedPad: any = null;

  dataLoading = true;

  startDate = null;

  nodeLevel = 'Well';

  get isLevelWell() {
    if (this.selectedNode && this.selectedNode.length) {
      return !this.enabledPads.includes(this.selectedNode[0]);
    }
    return true;
  }

  get isSearchGroupFeedback() {
    return gptModule.gptAiGroupFeedback.length > 4;
  }

  selectedAssignee: any = [
    {
      value: workflowModule.user.email,
      text: `${workflowModule.user.firstName} ${workflowModule.user.lastName}`,
    },
  ];

  selectedNode: any = [];

  hideSignalView = false;

  @Watch('selectedNode')
  onSelectedNodeUpdate() {
    this.hideSignalView = true;
    this.$nextTick(() => {
      this.hideSignalView = false;
    });
  }

  @Watch('startDate')
  onStartDateUpdate() {
    this.hideSignalView = true;
    this.$nextTick(() => {
      this.hideSignalView = false;
    });
  }

  actionErrors: any = [];

  selectedWell: any = null;

  showSelectedSignals = false;

  windowWidth = window.innerWidth;

  selectedActionCategory = '';

  comment = '';

  confirmationPopup: any = {};

  get isExpandGroupsCollapseState() {
    return Object.values(this.accordianState).some((v) => v);
  }

  updateAccordianState(predictionType) {
    this.accordianState[predictionType] = !this.accordianState[predictionType];
  }

  toggleGroupExpand() {
    console.log(this.isExpandGroupsCollapseState);
    const isExpandGroupsCollapseState = this.isExpandGroupsCollapseState;
    this.$nextTick(() => {
      for (const key in this.accordianState) {
        this.accordianState[key] = !isExpandGroupsCollapseState;
      }
    });
  }

  updateSparklineVal(option, gptAiQueryGroup) {
    console.log(option);
    console.log(gptAiQueryGroup);
    this.isLoadingSearchGroupResults = true;
    if (
      option &&
      gptAiQueryGroup['wells'].length &&
      gptAiQueryGroup['wells'][0].typeOfData
    ) {
      gptModule.setSparklineVal({
        query: gptAiQueryGroup['wells'][0].typeOfData,
        val: option,
      });
    }
    this.$nextTick(() => {
      this.isLoadingSearchGroupResults = false;
    });
  }

  itemComponent = TasqAIGroupItem;

  get hideDefaultGroups() {
    return this.gptAiQueryGroup.query || this.isLoadingSearchGroupResults;
  }

  toggleSignalSearch() {
    this.showWellSignalSearch = !this.showWellSignalSearch;
    this.startDate = null;
    this.selectedNode = [];
  }

  searchQuery = '';

  sumByKey(array, key = 'defermentValue') {
    const sum = array.reduce((acc, obj) => {
      // Ensure the key exists in the object and is a number
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'number') {
        acc += obj[key];
      }
      return acc;
    }, 0);
    return parseFloat(sum.toFixed(0)).toLocaleString();
  }

  changeGroupView(type, value) {
    this.groupViewState[type] = value;
  }

  closeAiResults() {
    if (this.hideDefaultGroups) {
      this.gptAiQueryGroup = {
        query: '',
        wells: [],
        sqls: '',
        signalTypes: [
          'Flowrate',
          'Tubing Pressure',
          'Static Pressure',
          'Casing Pressure',
        ],
      };
      this.searchQuery = '';
    } else {
      this.$emit('close');
    }
  }

  get showDataEntryState() {
    return Object.keys(this.accordianState)
      .map((key) => {
        return {
          [key]: !!gptModule.selectedAiGroupWellsObj.find(
            (w) => w.typeOfData === key
          ),
        };
      })
      .reduce((accumulator, currentObject) => {
        return { ...accumulator, ...currentObject };
      }, {});
  }

  isLoadingSearchGroupResults = false;

  signalSearchGroupName = '';
  updateDataLabel(data) {
    console.log(data);
    this.signalSearchGroupName = data;
  }

  defaultHeightSearch = '24px';

  async onSearchQueryUpdate(event = null) {
    this.enableFeedback = false;
    if (event && event.key && event.key == 'Enter') {
      event.preventDefault();
    } else if (event && event.key) {
      event.target.style.height = 'auto';
      event.target.style.height = `${event.target.scrollHeight}px`;
      this.defaultHeightSearch = `${event.target.scrollHeight}px`;

      return;
    }

    this.signalSearchLastPayload = null;

    const query = this.searchQuery;
    if (query.length < 3) return;
    try {
      this.isLoadingSearchGroupResults = true;
      this.gptAiQueryGroup['query'] = query;
      this.gptAiQueryGroup['wells'] = [];

      gptModule.resetGroupFeedback();

      const payload = {
        operator_name: this.operatorDetails['operatorName'],
        query: query,
      };

      gptModule.resetGptAiGroupWells();

      const { data } = await axiosClient.post('/tasq-ai/sql-prompts', payload);

      this.signalSearchLastPayload = payload;

      if (data.response && data.response.length) {
        const searchGroup = data.response
          .map((w) => {
            const key = w.name ? 'name' : 'nodeid';
            const timeKey = Object.keys(w).find((k) =>
              k.toLowerCase().includes('time')
            );
            const baseObject = {
              ...assetsModule.enabledPads.find((t) => t.wellName === w[key]),
              ...this.tasqs.find((t) => t.wellName === w[key]),
              isQueryResult: true,
              typeOfData: data.query,
            };

            if (timeKey) {
              baseObject['matchingData'] = { start_time: w[timeKey] };
            }
            return baseObject;
          })
          .filter(
            (i) => i && Object.keys(i) && Object.keys(i).length && i.wellName
          );

        gptModule.setAnimateState(true);
        this.gptAiQueryGroup['query'] = data.query;
        this.gptAiQueryGroup['sql'] = data.sql_query;
        this.gptAiQueryGroup['wells'] = searchGroup;

        (async () => {
          for (const [i, element] of searchGroup.entries()) {
            if (i > 8) {
              break;
            }
            await sleep(100);
            gptModule.addGptAiGroupWells(element);
          }

          gptModule.setGptAiGroupWells(searchGroup);
        })();
        gptModule.setAnimateState(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoadingSearchGroupResults = false;
    }
  }

  get gptAiGroupWells() {
    return gptModule.gptAiQueryGroupWells;
  }

  get shareLinkGroupIsOwnerIsMe() {
    // return false
    return (
      this.shareLinkGroup &&
      this.shareLinkGroup.settings &&
      this.shareLinkGroup.created_by === workflowModule.user.email
    );
  }

  get shareLinkGroupIsAlreadySubscribed() {
    return (
      (this.shareLinkGroup &&
        this.shareLinkGroup.settings &&
        this.shareLinkGroup.settings.subscribers &&
        this.shareLinkGroup.settings.subscribers.includes(
          workflowModule.user.email
        )) ||
      this.shareLinkGroup.created_by === workflowModule.user.email
    );
  }

  isSavingGroup = false;

  async toggleSubcriptionOnShareLinkGroup(group) {
    // if (group.settings.subscribers.includes(workflowModule.user.email)) {
    //   group.settings.subscribers = group.settings.subscribers.filter(
    //     (s) => s !== workflowModule.user.email
    //   );
    // } else {
    //   group.settings.subscribers.push(workflowModule.user.email);
    // }
    await this.performActionOnGroup({ action: 'subscribe', group });
    this.$router.push({ name: 'TasqAi' });
  }

  async cancelPopupAction() {
    this.confirmationPopup = {};
    this.showGroupSettings = !this.showGroupSettings;
    this.$nextTick(() => {
      this.showGroupSettings = !this.showGroupSettings;
    });
  }

  async confirmPopupAction() {
    const group = this.confirmationPopup.data;
    if (this.confirmationPopup.action === 'delete') {
      await axiosClient.delete(
        `/tasq-ai/delete-well-group/${this.confirmationPopup.data._id}`
      );
      this.$tasqAlert({
        title: 'Success',
        message: 'Tasq AI Group deleted! ',
        type: 'success',
      });
    } else if (this.confirmationPopup.action === 'publish') {
      let { settings, query } = group;

      settings = {
        ...settings,
        is_published: true,
      };

      await axiosClient.post('/tasq-ai/update-well-group', {
        id: group._id,
        settings: settings,
        query: query,
      });
      this.$tasqAlert({
        title: 'Success',
        message: 'Tasq AI Group Published! ',
        type: 'success',
      });
    } else if (this.confirmationPopup.action === 'subscribe') {
      let { settings, query } = group;

      settings = {
        ...settings,
        is_published: true,
      };

      settings = {
        ...settings,
        is_published: true,
      };

      if (!settings.subscribers.includes(workflowModule.user.email)) {
        settings.subscribers.push(workflowModule.user.email);
      } else if (!group.subscriber) {
        settings.subscribers = settings.subscribers.filter(
          (s) => s !== workflowModule.user.email
        );
      }

      await axiosClient.post('/tasq-ai/update-well-group', {
        id: group._id,
        settings: settings,
        query: query,
      });
      this.$tasqAlert({
        title: 'Success',
        message: 'Tasq AI Group Subscription Updated! ',
        type: 'success',
      });
    }

    this.confirmationPopup = {};

    await sleep(2300);
    await this.getAiGroups();
    this.showGroupSettings = !this.showGroupSettings;
    this.$nextTick(() => {
      this.showGroupSettings = !this.showGroupSettings;
    });
  }

  async performActionOnGroup({ action, group }) {
    try {
      if (action === 'delete') {
        this.confirmationPopup = {
          header: 'Delete Group',
          question: 'Are you sure you want to delete this group?',
          information: 'All information will be lost.',
          action: 'delete',
          confirmBtnText: 'Delete',
          popupType: 'danger',
          data: group,
        };
        return;
      } else if (action === 'publish') {
        this.confirmationPopup = {
          header: 'Pubish Group',
          question: 'Are you sure you want to publish this group?',
          information: 'This group will be available to all users.',
          action: 'publish',
          popupType: 'success',
          confirmBtnText: 'Publish',
          data: group,
        };
        return;
      } else if (action === 'subscribe') {
        let actionText = 'Unsubscribe';
        if (!group.settings.subscribers.includes(workflowModule.user.email)) {
          actionText = 'Subscribe';
        }

        this.confirmationPopup = {
          header: `${actionText} from group list`,
          question: `Are you sure you want to ${actionText.toLowerCase()} this group?`,
          information:
            actionText === 'Subscribe'
              ? `You will receive updates from this group`
              : `You will no longer receive updates from this group.`,
          action: 'subscribe',
          popupType: actionText === 'Subscribe' ? 'success' : 'danger',
          confirmBtnText: actionText,
          data: group,
        };
        return;
      }
      await sleep(2300);
      await this.getAiGroups();
      this.showGroupSettings = !this.showGroupSettings;
      this.$nextTick(() => {
        this.showGroupSettings = !this.showGroupSettings;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.isSavingGroup = false;
    }
  }

  async saveAiGroup() {

    if (
        this.isSavingGroup ||
      ((!this.isSearchGroupFeedback && this.enableFeedback) && this.gptAiGroupWells.length)
    )
      return;
    this.isSavingGroup = true;
    try {
      await axiosClient.post('/tasq-ai/save-well-groups', {
        operator_name: this.operatorDetails['operatorName'],
        query: this.gptAiQueryGroup['query'],
        sql_query: this.gptAiQueryGroup['sql'],
        response: this.gptAiGroupWells.map((w) => {
          return { name: w.wellName, matchingData: w.matchingData };
        }),
      });

      if (this.gptAiGroupWells.length === 0) {
        const title = `Tasq Ai ${this.operatorDetails['operatorName']} - ${this.gptAiQueryGroup['query']}`;
        let description = ``;

        if (this.signalSearchLastPayload) {
          description = `Search Query: ${this.signalSearchLastPayload.query}`;
        }

        workflowModule.postLinearBug({
          title,
          description,
          labelID: '0a03f648-0fde-4c7e-8373-0e7f382d6d2c',
        });
      }
      this.$tasqAlert({
        title: 'Success',
        message: 'New Tasq AI Group saved! ',
        type: 'success',
      });
      this.closeAiResults();
      await sleep(2300);
      await this.getAiGroups();
    } catch (error) {
      console.log(error);
    } finally {
      this.isSavingGroup = false;
    }
  }

  shareLinkGroup: any = {};

  savedGroups: any = [];

  savedGroupsForList: any = [];

  async getAiGroups() {
    try {
      console.log(this.gptAiQueryGroup);

      const { data } = await axiosClient.get('/tasq-ai/saved-groups', {
        params: {
          operator_name: this.operatorDetails['operatorName'],
        },
      });

      if (data && data.length) {
        const now = new Date().getTime();
        const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day

        const groupDataByDate = (date) => {
          const createdAt = new Date(date).getTime();
          const diffDays = Math.floor((now - createdAt) / oneDay);

          if (diffDays < 1) return 'Today';
          else if (diffDays < 2) return 'Yesterday';
          else if (diffDays < 8) return 'Previous 7 days';
          else if (diffDays < 31) return 'Previous 30 days';
          else return 'Older';
        };

        let saveData = data.map((d) => {
          this.accordianState[d.query] = true;
          this.groupViewState[d.query] = 'In line view';
          return {
            ...d,
            wells: d.response
              .map((w) => ({
                ...this.tasqs.find((t) => t.wellName === w.name),
                isQueryResult: false,
                typeOfData: d.query,
                matchingData: w.matchingData,
                isSignalSearchResult:
                  w.matchingData && w.matchingData.start_time ? true : false,
              }))
              .filter((i) => i && Object.keys(i).length),
            groupByKey: groupDataByDate(d.created_at),
          };
        });

        console.log('saved data');
        console.log(saveData);

        this.savedGroupsForList = saveData;
        saveData = saveData.filter(
          (j) =>
            j.created_by === workflowModule.user.email ||
            (j.settings &&
              j.settings.is_published &&
              j.settings.subscribers.includes(workflowModule.user.email))
        );

        // Grouping saved data by time categories using groupByKey
        let groupedData = saveData.reduce((acc, item) => {
          const groupByKey = item.groupByKey;
          if (!acc[groupByKey]) acc[groupByKey] = [];
          acc[groupByKey].push(item);
          return acc;
        }, {});

        // Define a custom sort order for the groups
        const sortOrder = {
          Today: 1,
          Yesterday: 2,
          'Previous 7 days': 3,
          'Previous 30 days': 4,
          Older: 5,
        };

        // Sort groups according to the custom sort order
        this.savedGroups = Object.keys(groupedData)
          .sort((a, b) => sortOrder[a] - sortOrder[b])
          .reduce((obj, key) => {
            obj[key] = groupedData[key];
            return obj;
          }, {});

        if (this.$route.params && this.$route.params.id) {
          this.shareLinkGroup = this.savedGroupsForList.find(
            (g) => g._id === this.$route.params.id
          );
        }
      }
    } catch (error) {
      console.error('Failed to fetch groups:', error);
    }
  }

  audioEntryGroup: any = [];

  async getLastActions() {
    try {
      let operator = this.operatorDetails['operatorName'];

      if (operator === 'demo') {
        operator = 'pdce';
      }

      const { data } = await axiosClient.get('/meta/latest-actions', {
        params: {
          operator: operator,
        },
      });

      gptModule.setLastestWellsLastActions(data);
      await gptModule.getWellStatuses();
      await workflowModule.getWellStatuses();

      this.setAudioGroup();
    } catch (error) {}
  }

  get timeseriesEndDate() {
    return gptModule.timeseriesEnd;
  }

  get timeseriesStartDate() {
    return gptModule.timeseriesStart;
  }

  get disabledDates() {
    return [
      (date) => {
        const startDate = new Date();
        startDate.setHours(20, 0, 0, 0);
        date.setHours(2, 0, 0, 0);
        return date >= startDate;
      },
    ];
  }

  signalSearchLastPayload: any = null;

  async searchSignalsQuery({ sensitivity }) {
    try {
      const selectedSignals = tasqSignalsModule.selectedSignals;
      this.gptAiQueryGroup['query'] = this.signalSearchGroupName;
      this.gptAiQueryGroup['signalTypes'] = [];
      this.isLoadingSearchGroupResults = true;
      this.enableFeedback = true;
      gptModule.resetGroupFeedback();
      gptModule.resetGptAiGroupWells();
      gptModule.setSelectedGroupWellRow(null);
      this.showWellSignalSearch = false;
      this.signalSearchLastPayload = null;

      await sleep(1000);

      // console.log(object);

      // const { data: signalsVectorized } = await axiosClient.get(
      //   '/meta/vectorized-signals',
      //   {
      //     params: {
      //       operator: this.operatorDetails['operatorName'],
      //     },
      //   }
      // );

      // let defaultSignals = [];

      // if (signalsVectorized && signalsVectorized.length) {
      //   defaultSignals = signalsVectorized[0]['signals'];
      // }
      // descriptions: selectedSignals.filter((s) => defaultSignals.includes(s)),

      // console.log(defaultSignals);

      console.log(selectedSignals);

      const payload = {
        operator_name: this.operatorDetails['operatorName'],
        end_time: this.timeseriesEndDate,
        start_time: this.timeseriesStartDate,
        nodeid: this.selectedNode[0],
        descriptions: selectedSignals,
        created_by: workflowModule.user.email,
        sensitivity: parseFloat(sensitivity),
      };
      this.signalSearchLastPayload = payload;
      const { data } = await axiosClient.post('/ai/timeseries-search', payload);

      const { response, vector_series } = data;

      gptModule.setGptAiGroupWells([]);
      let searchGroup: any = [];
      if (response && response.length) {
        if (this.isLevelWell) {
          searchGroup = response
            .map((w) => {
              return {
                ...this.tasqs.find((t) => t.wellName === w.nodeid),
                isSignalSearchResult: true,
                typeOfData: this.signalSearchGroupName,
                isWellLevel: true,
                isQueryResult: true,
                matchingData: w['matches'][0],
                matches: w['matches'],
              };
            })
            .filter((i) => i && Object.keys(i) && Object.keys(i).length);
        } else {
          searchGroup = response
            .map((w) => {
              return {
                ...assetsModule.enabledPads.find(
                  (t) => t.wellName === w.nodeid
                ),
                isSignalSearchResult: true,
                typeOfData: this.signalSearchGroupName,
                isQueryResult: true,
                isWellLevel: false,
                matchingData: w['matches'][0],
              };
            })
            .filter((i) => i && Object.keys(i) && Object.keys(i).length);
        }

        console.log(searchGroup);
        gptModule.setAnimateState(true);
        this.gptAiQueryGroup['query'] = this.signalSearchGroupName;
        this.gptAiQueryGroup['sql'] = '';
        this.gptAiQueryGroup['wells'] = searchGroup;

        if (searchGroup.length) {
          this.gptAiQueryGroup['signalTypes'] =
            searchGroup[0].matchingData['signal_types'];
          gptModule.setSparklineVal({
            query: this.gptAiQueryGroup['query'],
            val: searchGroup[0].matchingData['signal_types'][0],
          });
        }

        (async () => {
          for (const [i, element] of searchGroup.entries()) {
            if (i > 8) {
              break;
            }
            await sleep(100);
            gptModule.addGptAiGroupWells(element);
          }

          gptModule.setGptAiGroupWells(searchGroup);
        })();
        gptModule.setAnimateState(false);

        this.isLoadingSearchGroupResults = false;

        const { data: tasq_tags } = await axiosClient.post(
          '/tasq-ai/timeseries-tags',
          {
            operator: this.operatorDetails['operatorName'],
            end_time: this.timeseriesEndDate,
            start_time: this.timeseriesStartDate,
            nodeid: this.selectedNode[0],
            query: this.signalSearchGroupName,
            signals: tasqSignalsModule.searchSignalsValues,
            vectors: vector_series,
          }
        );
        this.gptAiQueryGroup['query'] =
          tasq_tags['best_tag'] === 'No Matches'
            ? this.signalSearchGroupName
            : tasq_tags['best_tag'];
        console.log(tasq_tags);
      } else {
        this.gptAiQueryGroup['wells'] = [];
        // gptModule.setSparklineVal({query: this.gptAiQueryGroup['query'],val: 'Flowrate'});
      }
    } catch (error) {
      this.gptAiQueryGroup['wells'] = [];
      console.log(error);
    } finally {
      this.isLoadingSearchGroupResults = false;
    }
  }



  reRunFeedback() {
    console.log(gptModule.gptAiGroupFeedback);
  }

  get sparklineVal() {
    return gptModule.aiGroupSparkLineSelectedSignal;
  }

  setAudioGroup() {
    const data = gptModule.lastestWellsLastActions;
    const audioEntryGroup = data.filter((e) => e.source_type === 'audio_entry');

    if (audioEntryGroup && audioEntryGroup.length) {
      this.accordianState['audio_entry'] = false;
      this.groupViewState['audio_entry'] = 'In line view';
      this.audioEntryGroup = audioEntryGroup
        .map((w) => w.nodeid)
        .map((e) => {
          return {
            ...this.tasqs.find((t) => t.wellName === e),
            isQueryResult: false,
            typeOfData: 'audio_entry',
          };
        })
        .filter((i) => i.wellName);
    }
  }

  get tasqs() {
    return tasqsListModule.tasqs(null, null, null, true);
  }

  accordianState = {};

  groupViewState = {};

  get groupedTasqs() {
    const data = this.tasqs.reduce((acc, tasq) => {
      let { predictionType } = tasq;

      // Skip tasks where predictionType is 'Producing'
      if (predictionType === 'Producing') {
        return acc;
      }

      if (predictionType === 'Off-Target RT') {
        predictionType = 'Real Time';
      }

      if (!acc[predictionType]) {
        acc[predictionType] = [];
      }
      acc[predictionType].push(tasq);
      return acc;
    }, {});

    const dataO = Object.keys(data).reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {});

    const dataView = Object.keys(data).reduce((acc, item) => {
      acc[item] = 'In line view';
      return acc;
    }, {});

    this.$nextTick(() => {
      this.accordianState = {
        ...dataO,
        ...this.accordianState,
      };
      console.log(this.accordianState);
      this.groupViewState = {
        ...this.groupViewState,
        ...dataView,
      };
    });

    return data;
  }

  get enabledPads() {
    return assetsModule.enabledPads.map((pad) => {
      return pad.text;
    });
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enabledWellsMapping() {
    const wells = assetsModule.enabledWells.map((well) => {
      return well.text;
    });

    const pads = assetsModule.enabledPads.map((pad) => {
      return pad.text;
    });
    return [...wells, ...pads];
  }

  async created() {
    this.dataLoading = true;
    const promiseList: any = [];
    if (this.searchQueryPassed && this.searchQueryPassed.length) {
      this.searchQuery = this.searchQueryPassed;
      this.$nextTick(async () => {
        await this.onSearchQueryUpdate();
      });
    }
    await this.getAiGroups();
    await this.getLastActions();
    promiseList.push(assetsModule.getEnabledWells());
    promiseList.push(assetsModule.getEnabledPads());

    Promise.all(promiseList).then(
      async () => {
        // console.log('resolved');
        await sleep(3000);
        this.dataLoading = false;
      },
      (err) => {
        console.log('Error:');
        console.log(err);
      }
    );
  }

  get isMobile() {
    return this.windowWidth < 768;
  }

  hideAudioEntryUI = false;
  async refreshAudioEntryPopup() {
    this.hideAudioEntryUI = true;
    this.$nextTick(() => {
      this.hideAudioEntryUI = false;
    });
    await sleep(3500);
    await this.getLastActions();
  }

  @Watch('tasqs')
  async onTasqUpdate() {
    await this.getAiGroups();
    this.setAudioGroup();
  }
}
